var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section"}},[_c('v-card',{attrs:{"color":"white"}},[_c('v-card-title',[_c('v-icon',{staticClass:"iconosVcards",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-cart-outline")]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"cabecera-tabla"},[_vm._v("Pedidos")])]),_c('v-col',{staticClass:"posicionBotonAgregar",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"fab":"","color":"primary"},on:{"click":function($event){return _vm.crearPedido()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),_c('v-text-field',{staticClass:"ml-auto pr-10",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"pl-10 pr-10",attrs:{"no-data-text":"No hay ningún pedido.","data-test":"vDatatable","headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"multi-sort":"","header-props":_vm.headerProps,"footer-props":{
        'items-per-page-text': 'Elementos por página:',
        pageText: '{1} de {2}',
        'items-per-page-all-text': 'Todos',
      }},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.estadoOrder",fn:function(ref){
      var item = ref.item;
return [(item.estadoOrder == 'pendiente')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_c('v-icon',[_vm._v("mdi-alert-outline ")])],1):_vm._e(),(item.estadoOrder == 'finalizado')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline ")])],1):_vm._e()]}},{key:"item.fechaFormateada",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(new Date(item.fecha).toLocaleString("es", { year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", }))+" ")])]}},{key:"item.nombreCompleto",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.pedidoOrder.cliente.nombre + " " + item.pedidoOrder.cliente.apellidos)+" ")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('ArticulosPedido',{attrs:{"itemPedido":item}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }