<template>
  <v-container tag="section">
    <v-card color="white">
      <v-card-title>
        <v-icon size="40" color="white" class="iconosVcards"
          >mdi-cart-outline</v-icon
        >
        <v-row>
          <v-col>
            <div class="cabecera-tabla">Pedidos</div>
          </v-col>
          <v-col class="posicionBotonAgregar" align="right">
            <v-btn @click="crearPedido()" fab color="primary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto pr-10"
        label="Buscar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <v-data-table
        no-data-text="No hay ningún pedido."
        class="pl-10 pr-10"
        data-test="vDatatable"
        :headers="headers"
        :items="orders"
        :search.sync="search"
        multi-sort
        :header-props="headerProps"
        :footer-props="{
          'items-per-page-text': 'Elementos por página:',
          pageText: '{1} de {2}',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template v-slot:[`item.estadoOrder`]="{ item }">
          <v-chip
            v-if="item.estadoOrder == 'pendiente'"
            color="warning"
            class="ma-2"
          >
            <v-icon>mdi-alert-outline </v-icon>
          </v-chip>

          <v-chip
            v-if="item.estadoOrder == 'finalizado'"
            color="primary"
            class="ma-2"
            label
          >
            <v-icon>mdi-checkbox-marked-circle-outline </v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.fechaFormateada`]="{ item }">
          <span>
            {{
              new Date(item.fecha).toLocaleString("es", {
                year: "numeric",
                month: "2-digit",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            }}
          </span>
        </template>

        <template v-slot:[`item.nombreCompleto`]="{ item }">
          <span>
            {{
              item.pedidoOrder.cliente.nombre +
              " " +
              item.pedidoOrder.cliente.apellidos
            }}
          </span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <ArticulosPedido v-bind:itemPedido="item"></ArticulosPedido>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import ArticulosPedido from './articulosPedido'
export default {
    name: 'TablaPedidos',

    components: {
        ArticulosPedido
    },
    data () {
        return {
            headerProps: {
                sortByText: 'Ordenar por'
            },
            url: process.env.VUE_APP_API_URL,
            search: '',
            orders: [],
            headers: [
                {
                    text: 'Nº pedido',
                    align: 'left',
                    value: 'id_pedido'
                },
                {
                    text: 'Gestor pedido',
                    align: 'left',
                    value: 'nombreHijoDistribuidor'
                },
                { text: 'Estado', align: 'left', value: 'estadoOrder' },
                { text: 'Nombre cliente', align: 'left', value: 'nombreCompleto' },
                { text: 'Fecha alta', align: 'right', value: 'fechaFormateada' },
                { text: 'Acciones', align: 'right', value: 'action', sortable: false }
            ]
        }
    },
    methods: {
        crearPedido () {
            this.$router.push('/crearPedido')
        },
        listOrders () {
            const emailComercial = this.$store.getters.getComercial.email
            const Comercial = this.$store.getters.getComercial
            let rutaApi
            if (Comercial.padre) {
                rutaApi = '/distribuidores/hijo/' + Comercial.id + '/pedidos'
            } else {
                rutaApi = '/distribuidores/' + Comercial.id + '/pedidos'
            }
            const getOrder = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.get(this.url + rutaApi, config)
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$router.push('/').catch(() => true)
                    }
                }
            }
            const processGetOrders = async () => {
                const get_result = await getOrder()
                if (get_result) {
                    this.orders = get_result.data.pedidosDistribuidor
                    this.orders.forEach((element) => {
                        if (element.nombreHijoDistribuidor === null) {
                            element.nombreHijoDistribuidor = emailComercial
                        }
                    })
                }
            }
            processGetOrders()
        }
    },
    created () {
        this.listOrders()
    }
}
</script>
<style>
</style>
