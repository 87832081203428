var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","fab":"","icon":"","large":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openOrder()}}},[_vm._v("mdi-eye")]),_c('v-dialog',{attrs:{"overlay-opacity":"0.8","transition":"dialog-bottom-transition"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.dialog = false}]},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3 mt-10",attrs:{"color":"primary","icon":"mdi-shopping","title":"Artículos"}},[_c('v-text-field',{staticClass:"ml-auto pr-10",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"no-data-text":"No hay ningún artículo.","headers":_vm.headers,"items":_vm.order,"search":_vm.search,"footer-props":{
              'items-per-page-text': 'Elementos por página:',
              pageText: '{1} de {2}',
              'items-per-page-all-text': 'Todos',
            },"header-props":_vm.headerProps},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.EstadoPedido)?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","fab":"","icon":"","large":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline ")])],1):_vm._e(),(!item.EstadoPedido && _vm.labelClienteCreado == 'false')?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","fab":"","icon":"","disabled":"","large":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}},{key:"item.contrato",fn:function(ref){
            var item = ref.item;
return [(item.contrato == 'Pendiente')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_c('v-icon',[_vm._v("mdi-alert-outline ")])],1):_vm._e(),(item.contrato != 'Pendiente' && item.contrato)?[_vm._v(" "+_vm._s(item.contrato)+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check ")])]:_vm._e()]}},{key:"item.servicio",fn:function(ref){
            var item = ref.item;
return [(item.servicio == 'Pendiente')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_c('v-icon',[_vm._v("mdi-alert-outline ")])],1):_vm._e(),(item.servicio != 'Pendiente' && item.servicio)?[_vm._v(" "+_vm._s(item.servicio)+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check ")])]:_vm._e()]}},{key:"item.numero",fn:function(ref){
            var item = ref.item;
return [(item.numero == 'Pendiente')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_c('v-icon',[_vm._v("mdi-alert-outline ")])],1):_vm._e(),(item.numero != 'Pendiente' && item.numero)?[_vm._v(" "+_vm._s(item.numero)+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check ")])]:_vm._e()]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Salir ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }