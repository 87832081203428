<template>
  <v-container>
    <TablaPedidos />
  </v-container>
</template>

<script>
import TablaPedidos from './components/tablaPedidos'
export default {
    name: 'verPedidos',
    components: {
        TablaPedidos
    }
}
</script>
