<template>
  <v-btn class="ml-1" color="primary" fab icon large>
    <v-icon @click="openOrder()">mdi-eye</v-icon>
    <v-dialog
      overlay-opacity="0.8"
      @keydown.esc="dialog = false"
      @keydown.enter="dialog = false"
      v-model="dialog"
      transition="dialog-bottom-transition"
    >
      <v-row>
        <v-col cols="12" md="12">
          <base-material-card
            color="primary"
            icon="mdi-shopping"
            title="Artículos"
            class="px-5 py-3 mt-10"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto pr-10"
              label="Buscar"
              hide-details
              single-line
              style="max-width: 250px"
            />
            <v-data-table
               no-data-text="No hay ningún artículo."
              :headers="headers"
              :items="order"
              :search="search"
              :footer-props="{
                'items-per-page-text': 'Elementos por página:',
                pageText: '{1} de {2}',
                'items-per-page-all-text': 'Todos',
              }"
              :header-props="headerProps"

            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  v-if="item.EstadoPedido"
                  class="ml-1"
                  color="primary"
                  fab
                  icon
                  large
                >
                  <v-icon>mdi-checkbox-marked-circle-outline </v-icon>
                </v-btn>
                <v-btn
                  v-if="!item.EstadoPedido && labelClienteCreado == 'false'"
                  class="ml-1"
                  color="primary"
                  fab
                  icon
                  disabled
                  large
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <template v-slot:[`item.contrato`]="{ item }">
                <v-chip
                  v-if="item.contrato == 'Pendiente'"
                  color="warning"
                  class="ma-2"
                >
                  <v-icon>mdi-alert-outline </v-icon>
                </v-chip>
                <template v-if="item.contrato != 'Pendiente' && item.contrato">
                  {{ item.contrato }}
                  <v-icon color="primary">mdi-check </v-icon>
                </template>
              </template>

              <template v-slot:[`item.servicio`]="{ item }">
                <v-chip
                  v-if="item.servicio == 'Pendiente'"
                  color="warning"
                  class="ma-2"
                >
                  <v-icon>mdi-alert-outline </v-icon>
                </v-chip>
                <template v-if="item.servicio != 'Pendiente' && item.servicio">
                  {{ item.servicio }}
                  <v-icon color="primary">mdi-check </v-icon>
                </template>
              </template>

              <template v-slot:[`item.numero`]="{ item }">
                <v-chip
                  v-if="item.numero == 'Pendiente'"
                  color="warning"
                  class="ma-2"
                >
                  <v-icon>mdi-alert-outline </v-icon>
                </v-chip>
                <template v-if="item.numero != 'Pendiente' && item.numero">
                  {{ item.numero }} <v-icon color="primary">mdi-check </v-icon>
                </template>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn depressed @click="dialog = false"> Salir </v-btn>
        </v-col>
      </v-row>
    </v-dialog>
  </v-btn>
</template>

<script>
export default {
    components: {
        'base-material-card': () => import('./../../portada/base/MaterialCard.vue')
    },
    name: 'articulosPedido',

    data: () => ({
        headerProps: {
            sortByText: 'Ordenar por'
        },
        dialog: '',
        search: '',
        url: process.env.VUE_APP_API_URL,
        headers: [
            {
                text: 'Cod artículo',
                align: 'center',
                value: 'codigo_articulo'
            },
            {
                text: 'Id linea',
                align: 'center',
                value: 'id_linea'
            },
            {
                text: 'Descripción',
                align: 'center',
                value: 'descripcion'
            },

            {
                text: 'Tipo',
                align: 'center',
                value: 'tipo'
            },

            {
                text: 'Estado',
                align: 'center',
                value: 'EstadoPedido'
            },
            {
                text: 'Tipo alta',
                align: 'center',
                value: 'tipo_alta_movil'
            },
            {
                text: 'Número/vlan',
                align: 'center',
                value: 'numero'
            },
            {
                text: 'Contrato',
                align: 'center',
                value: 'contrato'
            },
            {
                text: 'Radius/Servicio',
                align: 'center',
                value: 'servicio'
            }
        ],
        order: [],
        EstadoPedidos: [],
        labelClienteCreado: ''
    }),

    methods: {
        openOrder () {
            this.order = []
            this.dialog = true
            this.labelClienteCreado = this.itemPedido.clienteCreado
            this.order = this.itemPedido.pedidoOrder.lineas
            this.EstadoPedidos = this.itemPedido.EstadoPedidos
            this.order.forEach((element) => {
                element.EstadoPedido = ''
            })

            this.EstadoPedidos.forEach((elementEstado) => {
                this.order.forEach((elementoOrder) => {
                    if (elementEstado.id_linea === elementoOrder.id_linea) {
                        elementoOrder.EstadoPedido = elementEstado.estado
                    }
                })
            })
        }
    },
    props: {
        itemPedido: {
            type: Object
        }
    }
}
</script>
